/* General container styling */
.container {
  font-family: Arial, sans-serif;
  margin: 20px;
}

/* Tabs styling */
.tabs {
  display: flex;
  border-bottom: 2px solid #ddd;
}

.tab {
  flex: 1;
  padding: 10px 20px;
  border: none;
  background: #f1f1f1;
  cursor: pointer;
  transition: background 0.3s;
}

.tab.active {
  background: #2a79ef;
  border-bottom: 2px solid #007bff;
  font-weight: bold;
  color: white;
}

.tab-content {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-top: -1px;
}

.tab-heading {
  font-size: 1.5em;
  margin-bottom: 10px;
}

/* Dropzone styling */
.dropzone {
  border: 2px dashed #007bff;
  border-radius: 4px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  background: #f9f9f9;
  transition: background 0.3s;
  min-height: 200px;
}

.dropzone:hover {
  background: #e9e9e9;
}

.dropzone p {
  margin: 10px 0;
}

/* Textarea styling */
.textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  resize: vertical;
}

/* Content display styling */
.file-content, .manual-content {
  margin-top: 20px;
}

pre {
  background: #f5f5f5;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow-x: auto;
}

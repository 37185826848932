td,th {
	font-size: 12px;
}

th.col-1 {
	width: 25px;
}


th.col-2 {
	width: 35px;

}

/***    General     ***/
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: "Roboto", sans-serif
}

body {
    margin: 0 auto
}

.m-5-auto {
    margin: 5rem auto
}

.primary-button {
    margin-top: 5rem;
    margin-right: 1rem;
    padding: .6rem;
    width: 10rem;
    background: #222;
    border: none;
    color: #fff;
    font-size: 1.2rem;
    transition: all .5s;
    cursor: pointer;
    text-transform: capitalize
}

/***    Landing Page     ***/
.main-title,
.main-para {
    color: #f1f1f1
}

.main-title {
    padding-top: 3rem;
/*    font-family: 'Fascinate', cursive;*/
    font-size: 45px;
    font-weight: 300;
    line-height: 1.2;
        font-family: "open sans",arial,sans-serif;
}

.fascinate {
    font-family: 'Fascinate', cursive;
}

.main-para {
 font-weight: 600;
    font-size: 22px;
        font-family: calibri;
    line-height: 1.5;
    color: silver;

}

#reg_btn span {
    display: inline-block;
    position: relative;
    transition: 0.5s;
}

#reg_btn span:after {
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
}

#reg_btn:hover span {
  padding-right: 25px;
}

#reg_btn:hover span:after {
  opacity: 1;
  right: 0;
}

/***    Login Page     ***/
h2 {
    font-weight: 300
}

form {
    display: inline-block;
    background: #f3f3f3;
    border: 1px solid #ddd;
    border-radius: 2px;
    padding: 2rem;
    margin: 2rem 0 1rem 0
}

form label {
    float: left;
    font-size: .9rem;
    margin: 0;
    padding: 0
}

.right-label {
    float: right;
    cursor: pointer
}

input {
    width: 15rem;
    padding: .3rem;
    border-radius: 5px;
    outline: none;
    border: none
}

#sub_btn {
    display: block;
    width: 100%;
    padding: .3rem;
    border: none;
    background: #222;
    color: #fff;
    border-radius: 3px;
}

#sub_btn:hover {
    background: #333;
    transition: all .5s
}

footer p {
    margin: 0;
    font-size: .9rem
}

/***    Register Page     ***/
#checkbox {
    width: 1rem
}

form span {
    font-size: .8rem
}

#reset_pass_lbl {
    float: left
}

/***    Home Page     ***/
.home-page-title {
    color: #222
}

/* CSS */
.button-63 {
  align-items: center;
  background-image: linear-gradient(144deg,#AF40FF, #5B42F3 50%,#00DDEB);
  border: 0;
  border-radius: 8px;
  box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
  box-sizing: border-box;
  color: #FFFFFF;
  display: flex;
  font-family: Phantomsans, sans-serif;
  font-size: 20px;
  justify-content: center;
  line-height: 1em;
  max-width: 100%;
  min-width: 140px;
  padding: 19px 24px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  cursor: pointer;
}

.button-63:active,
.button-63:hover {
  outline: 0;

}/* CSS */
.button-62 {
  align-items: center;
  border: 0;
  border-radius: 8px;
  box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
  box-sizing: border-box;
  color: #FFFFFF;
  display: flex;
  font-family: Phantomsans, sans-serif;
  font-size: 16px;
  justify-content: center;
  line-height: 1em;
  max-width: 100%;
  min-width: 70px;
  padding: 10px 19px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  cursor: pointer;
  background: black;
}

.button-62:active,
.button-62:hover {
  outline: 0;
}

@media (min-width: 768px) {
  .button-63 {
    font-size: 24px;
    min-width: 196px;
  }
}


 
.maia-cols {
 
    width: 103.067484663%;
}



 
.maia-col-4 {
    width: 30.3571428571%;
}
.maia-col-1, .maia-col-2, .maia-col-3, .maia-col-4, .maia-col-5, .maia-col-6, .maia-col-7, .maia-col-8, .maia-col-9, .maia-col-10, .maia-col-11, .maia-col-12 {
    float: left;
    margin: 0 1.4880952381% 20px;
}
 

.header-ps {
    color: white;
    margin-bottom: 10px;
}

.st0 {
    fill:"#000000";
} 




/* For WebKit based browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 6px; /* Adjust the width as needed */
}

::-webkit-scrollbar-track {
  background-color: #071147; /* Background color of the track */
  border-radius: 3px; /* Rounded corners of the track */
}

::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar thumb */
  border-radius: 3px; /* Rounded corners of the thumb */
}

/* For Firefox */
/* Note: Firefox supports scrollbar customization from version 64 onwards */
/* You may need to add vendor prefixes for earlier versions */
/* See: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Scrollbars */

/* Example styles for Firefox scrollbar */
* {
  scrollbar-width: thin; /* or auto */
  scrollbar-color: #071147 #f1f1f1; /* Color of the scrollbar thumb and track */
}

.hover:hover {
    background-color: green !important;
}


pre { white-space: pre-wrap !important;
background-color:white !important;
padding: 30px !important;
line-height: 40px !important;
 }


 

.big_button {

                display: inline-block;
                outline: 0;
                border: 1px solid green;
                cursor: pointer;
                font-weight: bold;
                color: green;
                font-size: 20px;
                height: 38px;
                padding: 8px 24px;
                border-radius: 50px;
                transition: all .2s ease-out;
                :hover{
                border-radius: 50px;
                    padding: 2px;
                }
                
 }



.code-box {
  width: 100%;
  minHeight: 20vh;
  background-color: #f4f4f4;
  border: 1px solid #ccc;
  padding: 10px;
  font-family: monospace;
  white-space: pre;
  overflow: auto;
}


.fixed-element {
    position: fixed; /* Fixes the element to the viewport */
    top: 0;           /* Positions it at the top of the page */
    left: 0;          /* Optional: Align it to the left of the page */
    width: 100vw;      /* Optional: Make it span the full width */
    background-color: #fff;  /* Optional: Set background color */
    z-index: 1000;    /* Optional: Ensures it's above other content */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3); /* Optional: Adds a shadow to make it stand out */
}

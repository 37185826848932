.dropbtn {
  font-size: 14px;
  border: none;
  height: 40px;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute; 
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {background-color: #ddd;}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {display: block;}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {color: #3e8e41;}
 
/* Modal Header */
#deleteModal .modal-header {
  padding: 2px 16px;
  background-color: #051837;
  color: white;
}

#chatModal .modal-header {
  background-color: #051837;
  color: white;
    padding: 2px 16px;
}
#shareModal .modal-header {
  background-color: #051837;
  color: white;
    padding: 2px 16px;
}


/* Modal Header */
#exampleModal .modal-header {
  padding: 2px 16px;
  background-color: #051837;
  color: white;
}

/* Modal Content */
#exampleModal  .modal-content {
  position: relative; 
  margin: auto;
  padding: 0; 
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
  animation-name: animatetop;
  animation-duration: 0.4s
}


#exampleModal  .modal-footer {
  padding: 0px; 
  color: white;
  padding-right: 12px;
  padding-top: 7px;
  background-color: white;
}
/* Modal Body */
.modal-body {padding: 0px !important;}

/* Modal Footer */
#chatModal .modal-footer {
  padding: 0px; 
  color: white;
  width: 50%;
  margin: 4px;
  border: 1px solid #dee2e6 !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      border-radius: 13px;
}

/* Modal Content */
#deleteModal  .modal-content {
  position: relative; 
  margin: auto;
  padding: 0; 
  width: 70%;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
  animation-name: animatetop;
  animation-duration: 0.4s
}

#chatModal .modal-content {
  position: relative; 
  margin: auto;
  padding: 0; 
  width: 100%;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
  animation-name: animatetop;
  animation-duration: 0.4s
}



/* Add Animation */
@keyframes animatetop {
  from {top: -300px; opacity: 0}
  to {top: 20; opacity: 1}
}


 .user-icon {
 	width: 30px;
    height: 30px;
    border: 1px solid silver;
    padding: 4px;
    border-radius: 4px;
    margin-right: 15px;
 }

    .message {
      padding: 12px;
      margin-bottom: 10px;
    }

    .user-message {
/*      background-color: #f5f5f5;*/
      padding: 6px;
/*    border-radius: 4px;*/
/*    font-family: 'Courier New', monospace;*/
    font-size: 14px;
    line-height: 1.5;
/*    color: #333;*/
    overflow-x: auto;
    text-wrap: wrap;
    }

 #chatbot:hover {
 box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.edit-icon {
	float: right;
}

.message {
	    display: flex;
}

input {
  min-height: 40px;
}

pre {
      padding: 15px;
    font-size: 14px;
    line-height: 1.5;
    color: #333;
    overflow-x: auto;
    text-wrap: wrap;
        background-color: #dcdcdc5e; 
        width: 100%;
}

.flex-container {
        padding-left:10px ;
            display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: 50px;
}




/* Bootstrap 4 text input with search icon */

.has-search .form-control {
    padding-left: 2.375rem;
}

.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
}

.menu-icon .icon {
        background: #2196f35e;
  
    height: 43px;
    width: 43px;
    border-radius: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.menus {

  display: flex;
  justify-content: flex-end;
  align-items: center;
          margin-top: -18px;
          margin-left: 30px;
}

.menus svg {
  margin-right: 13px;
}


 
  .custom-col {
    flex-basis: 25%;
    max-width: 25%;
    border-radius: 8px;
    min-width: 300px;
  }

.dropdown-content a {
  border-bottom: 1px solid #eee;
}


.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.flex-row2 {
  display: flex;
  flex-direction: row;
 
}

.prompt_code {
  width: 70%;
  border-right: 1px solid #e1e1e1;
}
.prompt_details {
   width: 30%;

}

.prompt_editor {
 height: 50vh;
}

.prompt_output {
 height: 33.5vh;

}

textarea{
        height: 100%;
        width: 99%;
        -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
        -moz-box-sizing: border-box;    /* Firefox, other Gecko */
        box-sizing: border-box;         /* Opera/IE 8+ */
}


    /* Custom styling for the input field */
        .custom-input-group {
            position: relative;
        }

        .custom-input {
            border-radius: 20px; /* Rounded corners */
            padding: 10px 35px 10px 10px; /* Add padding for better appearance */
        }

        .custom-dropdown-icon {
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-50%);
        }


           /* Custom styling for the search results list */
        .custom-list-group {
            margin-top: 5px; /* Add space between the input and the results */
        }

        /* Custom styling for result items */
        .result-item {
            display: flex;
            padding: 10px;
            border-bottom: 1px solid #ddd;
            border-left: 0px solid #ddd;
            border-right: 0px solid #ddd;
            margin-bottom: 0px;
            cursor: pointer;
        }

.result-item:hover {
  background-color: #2196f33d;
}
    

        .result-content {
            flex-grow: 1;
        }

        .result-name {
            font-weight: bold;
        }

        .result-description {
            color: #666;
        }

